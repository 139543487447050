import DropDownMenu_VerticalRibbonsMenuButtonSkinComponent from '../components/DropDownMenu/viewer/skinComps/VerticalRibbonsMenuButtonSkin/VerticalRibbonsMenuButtonSkin.skin';
import DropDownMenu_VerticalRibbonsMenuButtonSkinController from '../components/DropDownMenu/viewer/DropDownMenu.controller';


const DropDownMenu_VerticalRibbonsMenuButtonSkin = {
  component: DropDownMenu_VerticalRibbonsMenuButtonSkinComponent,
  controller: DropDownMenu_VerticalRibbonsMenuButtonSkinController
};


export const components = {
  ['DropDownMenu_VerticalRibbonsMenuButtonSkin']: DropDownMenu_VerticalRibbonsMenuButtonSkin
};

